import styles from "./ImageUploader.module.scss";
import { Upload, UploadFile } from "antd";
import { errorText, componentsText } from "utils/text";

import { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { ImagesVideosList } from "./ImagesVideosList";
import {
  acceptedVideoType,
  LIMIT_IMAGE_SIZE,
  LIMIT_IMAGES_VIDEOS_COUNT,
  LIMIT_VIDEO_SIZE,
  acceptedImageType,
} from "utils/const";
import { images } from "assets/iconString";
import i18next from "i18next";
import { IImageUploaderFileChange } from "utils/interface";
import useConvertHeic from "hooks/useConvertHeic";
import Loading from "pages/Loading/Loading";
import { checkTypeAllowed } from "utils/helper";
import { useTranslation } from "react-i18next";
import ModalCropImage from "./ModalCropImage";

type UploaderType = "takePhoto" | "uploadPhoto";
interface IProps {
  type: UploaderType;
  isMultiple?: boolean;
  showTooltip?: boolean;
  handleErrorText?({ errorText }: { errorText: Array<string> }): void;
  onFileChange?: (data: any) => void;
  filesListFromProps?: Array<{
    url: string;
    file: {
      name: string;
      type: string;
    };
  }>;
  acceptedFilesTypeArr: Array<string>;
  defaultText?: string;
  limitMaxImage?: number;
  aspect?: "4:3" | "3:4" | "1:1";
  itemSizeWidth?: number | string;
  itemSizeHeight?: number | string;
  hasCrop?: boolean;
  isImageStoreDownloaded?: boolean;
  videoDimensions?: any;
  setVideoDimensions?(data: any): void;
}

export default function ImageUploader({
  type,
  isMultiple = true,
  showTooltip = true,
  handleErrorText,
  onFileChange,
  filesListFromProps,
  acceptedFilesTypeArr,
  defaultText = componentsText.imagesVideosSelection,
  limitMaxImage = LIMIT_IMAGES_VIDEOS_COUNT,
  aspect = "1:1",
  itemSizeWidth,
  itemSizeHeight,
  hasCrop = true,
  isImageStoreDownloaded = false,
  videoDimensions,
  setVideoDimensions,
}: IProps) {
  const { t } = useTranslation();
  let captureType: any;
  if (type === "takePhoto") {
    captureType = "environment";
  }

  const [openModal, setOpenModal] = useState<any>(false);
  const [curFileList, setCurFileList] = useState<any[]>([]);
  const [curErrorTextArr, setCurErrorTextArr] = useState<string[]>([]);
  const { isConvertingHeic, convertHeicToAny } = useConvertHeic();
  const [rawFileList, setRawFileList] = useState<any>([]);
  useEffect(() => {
    if (filesListFromProps && !isEqual(filesListFromProps, curFileList)) {
      setCurFileList(filesListFromProps as any);
    }
  }, [filesListFromProps]);

  const handleFileChange = async (currentFile: any) => {
    let urlImage;
    let fileAfterProcessed = currentFile;

    if (
      RegExp(/(heic|heif)/i).test(`${currentFile.type} ${currentFile?.name}`)
    ) {
      const conversionResult = await convertHeicToAny(currentFile);
      fileAfterProcessed = conversionResult?.fileAfterProcessed;
      urlImage = conversionResult?.urlImage;
    } else {
      urlImage = window.URL.createObjectURL(fileAfterProcessed);
    }

    const video: any = document.createElement("video");

    video.src = urlImage;
    video.onloadedmetadata = () => {
      setVideoDimensions?.({
        width: video.videoWidth,
        height: video.videoHeight,
      });
      // URL.revokeObjectURL(urlImage); // Giải phóng URL sau khi sử dụng
    };

    let errorText = "";
    if (
      !checkTypeAllowed({
        curType: fileAfterProcessed?.type,
        allowedTypes: acceptedFilesTypeArr,
      })
    ) {
      errorText = i18next.t("errorText.wrongFormatFileDanger");
    } else if (
      (checkTypeAllowed({
        curType: fileAfterProcessed?.type,
        allowedTypes: acceptedImageType,
      }) &&
        fileAfterProcessed?.size >= LIMIT_IMAGE_SIZE) ||
      (checkTypeAllowed({
        curType: fileAfterProcessed?.type,
        allowedTypes: acceptedVideoType,
      }) &&
        fileAfterProcessed?.size >= LIMIT_VIDEO_SIZE)
    ) {
      errorText = i18next.t("errorText.largeFileDanger");
    }
    return { url: urlImage, file: fileAfterProcessed, errorText };
  };

  const handleRemoveWithIndex = (indexToDelete: number) => {
    const newFileList = curFileList.slice();
    window.URL.revokeObjectURL(newFileList[indexToDelete]?.file);
    newFileList.splice(indexToDelete, 1);
    setCurFileList(
      newFileList.map((newFileItem) => {
        // if (newFileItem?.url.includes("blob")) {
        //   window.URL.revokeObjectURL(newFileItem?.file);
        //   return {
        //     ...newFileItem,
        //     url: window.URL.createObjectURL(newFileItem?.file),
        //   };
        // }
        return newFileItem;
      })
    );
    if (onFileChange) {
      onFileChange({ files: newFileList });
    }
  };

  const handleRemove = (file: UploadFile) => {
    const index = curFileList.indexOf(file);
    handleRemoveWithIndex(index);
  };
  const handleBeforeUpload = async (_: UploadFile, fileList: UploadFile[]) => {
    const validFileList = fileList.filter((file: UploadFile) => {
      const isValid = acceptedFilesTypeArr?.includes(file.type || "");
      if (!isValid) console.warn(`Invalid file: ${file.name} (${file.type})`);
      return isValid;
    });

    setRawFileList(validFileList);
    try {
      if (
        hasCrop &&
        validFileList.filter(
          (item: any) =>
            item.type.includes("image") && item.type !== "image/gif"
        ).length > 0
      ) {
        setOpenModal(true);
      } else {
        const fileListAfterChanged = [];
        for (let fileItem of validFileList) {
          const fileAfterChanged = await handleFileChange(fileItem);
          fileListAfterChanged.push(fileAfterChanged);
        }
        if (!isEqual(curFileList, fileListAfterChanged)) {
          // Only 5 items
          const newCurFileList = [
            ...curFileList,
            ...fileListAfterChanged,
          ].slice(0, limitMaxImage);
          setCurFileList(newCurFileList);
          if (onFileChange) {
            onFileChange({ files: newCurFileList });
          }
        }
      }
      return false;
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  useEffect(() => {
    if (curFileList) {
      let newErrorTextArr = [];
      if (curFileList?.length > limitMaxImage) {
        newErrorTextArr.push(errorText.manyFilesDanger);
      }
      curFileList?.forEach((fileItem, fileIndex) => {
        if (fileItem.errorText) {
          newErrorTextArr.push(
            `${fileItem.errorText} (at index ${fileIndex + 1})`
          );
        }
      });
      setCurErrorTextArr(newErrorTextArr);

      if (handleErrorText) {
        handleErrorText({ errorText: newErrorTextArr });
      }
    }
  }, [curFileList]);

  return (
    <div>
      {isConvertingHeic && <Loading />}
      <div className={styles.txtLabel}></div>
      {curFileList?.length > 0 && (
        <ImagesVideosList
          isDeletable={true}
          data={curFileList.map((item) => {
            return {
              url: item?.url,
              size: item?.file?.size || item?.size,
              name: item?.file?.name || item?.name || item?.path?.slice(10),
              type: item?.file?.type || item?.type,
            };
          })}
          handleDelete={(indexToDelete: number) => {
            handleRemoveWithIndex(indexToDelete);
          }}
          isWrapped={true}
          acceptedFilesTypeArr={acceptedFilesTypeArr}
          itemSizeWidth={itemSizeWidth}
          itemSizeHeight={itemSizeHeight}
          isImageStoreDownloaded={isImageStoreDownloaded}
        />
      )}
      {curFileList?.length < limitMaxImage && (
        <Upload
          multiple={isMultiple}
          customRequest={() => null}
          className={styles.uploadCustom}
          listType="picture-card"
          showUploadList={false}
          onPreview={() => null}
          onRemove={handleRemove}
          beforeUpload={handleBeforeUpload}
          accept={acceptedFilesTypeArr?.join(", ")}
          capture={captureType}
        >
          <div className={styles.uploadContent}>
            <img src={images.uploadIcon} />
            <div>{defaultText}</div>
          </div>
        </Upload>
      )}
      {/* {curErrorTextArr?.length > 0 && (
        <div>
          {curErrorTextArr.map((curErrorTextItem, curErrorTextIndex) => {
            return (
              <div key={`${curErrorTextIndex}`} className={styles.txtError}>
                {curErrorTextItem}
              </div>
            );
          })}
        </div>
      )} */}
      <ModalCropImage
        open={openModal}
        aspect={aspect}
        listImages={rawFileList}
        onFinishCrop={async (data: any) => {
          const fileListAfterChanged = [];
          for (let fileItem of data) {
            const fileAfterChanged = await handleFileChange(fileItem);
            fileListAfterChanged.push(fileAfterChanged);
          }
          if (!isEqual(curFileList, fileListAfterChanged)) {
            const newCurFileList = [
              ...curFileList,
              ...fileListAfterChanged,
            ].slice(0, limitMaxImage);
            setCurFileList(newCurFileList);
            if (onFileChange) {
              onFileChange(newCurFileList);
            }
          }
          setOpenModal(false);
          setRawFileList([]);
        }}
      />
    </div>
  );
}

export const REGEX_EMAIL = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g;
export const REGEX_PHONE = /^(\+84|\+81|0)[1-9]([0-9]{8,9})$/;
export const REGEX_PASSWORD =
  /^(?=.*?[0-9])(?=.*?[A-Za-z])[A-Za-z0-9!@#$%^&*.]{8,25}$/;
export const REGEX_KATAKANA = /^[\u30A0-\u30FF\u3005]+$/i;
export const REGEX_JAPANESE = /[一-龯]|[ぁ-んァ-ン]|[Ａ-ｚ]/gi;
export const REGEX_EMOJI =
  /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu;
export const REGEX_CONTAIN_SPECIAL_CHARS =
  /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/gi;
export const REGEX_IMAGE_URI = /\.(gif|jpe?g|png|jfif|heic)$/i;
export const REGEX_LAT_LNG =
  /^(-?([1-8]?\d(\.\d+)?|90(\.0+)?)),\s*(-?((1[0-7]\d(\.\d+)?|180(\.0+)?)|(\d{1,2}(\.\d+)?)))$/;

export const REGEX_CREDENTIAL = /^[A-Za-z0-9]{6,25}$/;
export const REGEX_PHONE_MAX = /^(\+84|\+81)[1-9]([0-9]{8,9})$/;

export const REGEX_URL = /^(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
export const REGEX_TWITTER =
  /^(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/([a-zA-Z0-9_]{1,15})$/;

export const REGEX_NOT_JAPANESE = /^[A-Za-z0-9!@#$%^&*.\s]*$/gi;
export const REGEX_NOT_JAPANESE_SPECIAL = /^[A-Za-z0-9!@#$%^&*.\s-_]*$/gi;
export const REGEX_LINE = /https?:\/\/((line|lin|li|works)\.(me|ee|do)).*/;

import {
  Checkbox,
  Input,
  Select,
  TimePicker,
  Upload,
  Form,
  TreeSelect,
  DatePicker,
  Radio,
} from "antd";
import Icon from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { images } from "assets/iconString";
import styles from "./styles.module.scss";
import CustomButton from "components/CustomButton";
import { ButtonType, EFormUser, PrimaryIdType, QueryKey } from "utils/enum";
import LabelInput from "components/LabelInput";
import {
  checkTypeAllowed,
  convertFileTypeFromUrl,
  handleErrorMessage,
  handlePreSignAvatar,
  handlePreSignListFiles,
} from "utils/helper";
import { ArrowLeft, DateIcon, IconEye, IconEyeOff } from "assets/icon";
import { useMutation, useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import ImageUploader from "components/Upload/ImageUploader";
import { commonValidate, RuleForm } from "utils/ruleForms";
import CustomNotification from "components/CustomNotification";
import Loading from "pages/Loading/Loading";
import classNames from "classnames";
import {
  acceptedAvatarType,
  acceptedDocumentsType,
  acceptedImageType,
  acceptedVideoType,
  LIMIT_AVATAR_SIZE,
} from "utils/const";
import { IImageUploaderFileChange } from "utils/interface";
import i18next from "i18next";
import useConvertHeic from "hooks/useConvertHeic";
import { TIFFViewer } from "react-tiff";
import {
  createUserApi,
  getDetailUserApi,
  getListCupSize,
  getListExperience,
  getListPanel,
  getListPickupRequest,
  updateUserApi,
} from "api/user";
import { NumericFormat } from "react-number-format";
import ImgCrop from "antd-img-crop";
import ModalCropImage from "components/Upload/ModalCropImage";
import {
  getIDCard,
  getListPrefecture,
  getListPrefectureApi,
} from "api/masterData";

export default function UserEdit() {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const url = useLocation()?.pathname;
  const userId = url?.split("/")?.[url?.split("/")?.length - 1];
  const { isConvertingHeic, convertHeicToAny } = useConvertHeic();

  const [avatar, setAvatar] = useState<any>();
  const [generalErrorText, setGeneralErrorText] = useState<{
    files?: string;
    registrationPermitFiles?: string;
  }>({
    files: "",
    registrationPermitFiles: "",
  });
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const [openModal, setOpenModal] = useState<any>(false);
  const [rawFileList, setRawFileList] = useState<any>([]);

  const avatarWatch = Form.useWatch("avatar", form);
  const primaryIdCurrent = Form.useWatch("primaryIdType", form);

  const getFormTypeFromUrl = (currentUrl: string) => {
    // done dung check form
    if (currentUrl?.includes("add-user")) {
      return EFormUser.CREATE;
    }
    return EFormUser.UPDATE;
  };

  const formType = getFormTypeFromUrl(url); //done dung checkform

  const { data: detailUser, isFetching: loadingDetailUser } = useQuery(
    //done getDetailUser
    [QueryKey.DETAIL_USER, userId],
    () => getDetailUserApi(`${userId}`),
    {
      enabled: !Number.isNaN(Number(userId)),
      onSuccess({ data }) {
        let experiences: any = [];
        data?.experiences?.forEach((item: any) => {
          if (item.experiences.length > 0) {
            item.experiences.forEach((sub: any) => {
              experiences.push(`${item.experienceGroup.id}-${sub.id}`);
            });
          } else {
            experiences.push(item.experienceGroup.id);
          }
        });
        setAvatar(data?.avatar?.url);
        form.setFieldsValue({
          ...data,
          dateOfBirth: dayjs(data?.dateOfBirth),
          experiences: data?.experiences ? experiences : undefined,
          email: data?.account?.email,
          phone: data?.account?.phone,
          cupSizeId: data?.cupSize?.id,
          panelId: data?.panel?.id,
          pickUpRequestId: data?.pickUpRequest?.id,
          // height: Number(data?.height),
          desiredStartTime: data?.desiredStartTime
            ? dayjs(data?.desiredStartTime, "HH:mm")
            : null,
          desiredEndTime: data?.desiredEndTime
            ? dayjs(data?.desiredEndTime, "HH:mm")
            : null,
        });
      },
    }
  );
  const { data: listExperiences, isFetching: loadingListExperiences } =
    useQuery([QueryKey.LIST_EXPERIENCES], () => getListExperience());

  const { data: listPickupRequest } = useQuery(["listPickupRequest"], () =>
    getListPickupRequest()
  );

  const { data: listPanelRequest } = useQuery(["listPanelRequest"], () =>
    getListPanel()
  );

  const { data: listCupSizeRequest } = useQuery(["listCupSizeRequest"], () =>
    getListCupSize()
  );

  const { data: listPrefecture } = useQuery(["listPrefecture"], () =>
    getListPrefecture()
  );

  const { data: listIDCard } = useQuery(["listIDCard"], () => getIDCard());

  const panelId = Form.useWatch("panelId", form);

  const treeExperience = listExperiences?.data?.map((item: any) => {
    if (item?.experience?.length > 0) {
      const experienceFormat = item?.experience?.map((sub: any) => ({
        value: `${item.id}-${sub.id}`,
        title: sub.value,
      }));
      return {
        value: item.id,
        title: item.value,
        children: experienceFormat,
      };
    }
    return { value: item.id, title: item.value };
  });

  const queryClient = useQueryClient();

  /**
   *
   * START CONDITIONAL RENDERING
   *
   */
  //done dung khi create use
  useEffect(() => {
    if (formType === EFormUser.CREATE)
      form.setFieldsValue({ dateOfBirth: dayjs("1999-07-01") });
  }, []);
  const handleSubmitCreate = async (payload: any) => {
    try {
      const experienceGroups: any = {};
      payload?.experiences?.forEach((item: any) => {
        if (typeof item === "number") {
          experienceGroups[item] = [];
        }
        if (typeof item === "string") {
          const arr: any = item.split("-");
          experienceGroups[arr[0]] = !!experienceGroups[arr[0]]
            ? [...experienceGroups[arr[0]], Number(arr[1])]
            : [Number(arr[1])];
        }
      });
      const experienceGroupsArr = Object.keys(experienceGroups).map(
        (item: any) => ({
          experienceGroupId: Number(item),
          experienceIds: experienceGroups[item],
        })
      );
      const filesToUpload = await handlePreSignListFiles({
        keyString: "files",
        payload,
      });
      const { avatarPathname } = await handlePreSignAvatar({
        payload,
        formType,
        avatarFromApi: detailUser?.data,
      });
      const objectToCreate = {
        avatar: avatarPathname
          ? {
              path: avatarPathname,
            }
          : undefined,

        files: filesToUpload,
        email: payload?.email?.length > 0 ? payload?.email : null,
        nickname: payload.nickname,
        phone: payload.phone,
        dateOfBirth: dayjs(payload.dateOfBirth).format("YYYY-MM-DD"),
        height: Number(payload?.height),
        weight: Number(payload?.weight),
        cupSizeId: payload?.cupSizeId,
        experiences:
          experienceGroupsArr?.length > 0 ? experienceGroupsArr : undefined,
        residence: payload?.residence?.length > 0 ? payload?.residence : null,
        pickUpRequestId: payload?.pickUpRequestId,
        desiredPickUpPlace:
          payload?.desiredPickUpPlace?.length > 0
            ? payload?.desiredPickUpPlace
            : null,
        panelId: payload?.panelId,
        selfPR: payload?.selfPR?.length > 0 ? payload?.selfPR : null,
        password: payload.password,
        desiredStartTime: payload.desiredStartTime
          ? dayjs(payload.desiredStartTime).format("HH:mm")
          : null,
        desiredEndTime: payload.desiredEndTime
          ? dayjs(payload.desiredEndTime).format("HH:mm")
          : null,
        additionalIdType: payload?.additionalIdType ?? [],
        primaryIdType: payload?.primaryIdType,
        areaId: payload?.areaId,
      };

      await createUserApi(objectToCreate);
      CustomNotification({
        type: "success",
        message: t("notification.success"),
      });
      navigate("/users");
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  const handleSubmitUpdate = async (payload: any) => {
    try {
      const experienceGroups: any = {};
      payload?.experiences?.forEach((item: any) => {
        if (typeof item === "number") {
          experienceGroups[item] = [];
        }
        if (typeof item === "string") {
          const arr: any = item.split("-");
          experienceGroups[arr[0]] = !!experienceGroups[arr[0]]
            ? [...experienceGroups[arr[0]], Number(arr[1])]
            : [Number(arr[1])];
        }
      });
      const experienceGroupsArr = Object.keys(experienceGroups).map(
        (item: any) => ({
          experienceGroupId: Number(item),
          experienceIds: experienceGroups[item],
        })
      );
      const filesToUpload = await handlePreSignListFiles({
        keyString: "files",
        payload,
      });

      const { avatarPathname, curAvatarId, type } = await handlePreSignAvatar({
        payload,
        formType,
        avatarFromApi: detailUser?.data,
      });
      const objectToUpdate = {
        avatar: avatarPathname
          ? {
              id: curAvatarId,
              path: avatarPathname,
              type,
            }
          : undefined,

        files: filesToUpload,
        email: payload?.email?.length > 0 ? payload?.email : null,
        nickname: payload.nickname,
        phone: payload.phone,
        dateOfBirth: dayjs(payload.dateOfBirth).format("YYYY-MM-DD"),
        height:
          (payload.height > 0 || payload.height !== "") && payload.height
            ? Number(payload.height)
            : null,
        weight:
          (payload.weight > 0 || payload.weight !== "") && payload.weight
            ? Number(payload.weight)
            : null,
        cupSizeId: payload?.cupSizeId,
        experiences:
          experienceGroupsArr?.length > 0 ? experienceGroupsArr : undefined,
        residence: payload?.residence?.length > 0 ? payload?.residence : null,
        pickUpRequestId: payload?.pickUpRequestId,
        desiredPickUpPlace:
          payload?.desiredPickUpPlace?.length > 0
            ? payload?.desiredPickUpPlace
            : null,
        panelId: payload?.panelId,
        selfPR: payload?.selfPR?.length > 0 ? payload?.selfPR : null,
        // password: payload.password,
        desiredStartTime: payload.desiredStartTime
          ? dayjs(payload.desiredStartTime).format("HH:mm")
          : null,
        desiredEndTime: payload.desiredEndTime
          ? dayjs(payload.desiredEndTime).format("HH:mm")
          : null,
        additionalIdType: payload?.additionalIdType ?? [],
        primaryIdType: payload?.primaryIdType,
        areaId: payload?.areaId,
      };

      // If we are updating a store
      if (formType === EFormUser.UPDATE) {
        await updateUserApi(Number(userId), objectToUpdate);
        CustomNotification({
          type: "success",
          message: t("notification.success"),
        });
        navigate("/users");
      }
    } catch (err) {
      handleErrorMessage(err);
    }
  };
  const handleSubmit = async (payload: any) => {
    if (formType === EFormUser.CREATE) {
      await handleSubmitCreate(payload);
      return;
    }
    await handleSubmitUpdate(payload);
  };
  const mutation = useMutation({ mutationFn: handleSubmit });

  const renderTitle = () => {
    if (formType === EFormUser.UPDATE) {
      return t("headerPage.editUser");
    }
    return t("headerPage.addUser");
  };

  const renderAvatarComponent = () => {
    if (!avatar) {
      return (
        <div>
          <img
            alt="avatar-store"
            src={images.avatarDefaultIcon}
            className={styles.avatar}
          />
        </div>
      );
    }
    if (
      acceptedAvatarType?.includes(
        convertFileTypeFromUrl(form.getFieldValue("avatar")?.url) ||
          convertFileTypeFromUrl(form.getFieldValue("avatar")?.name) ||
          ""
      )
    ) {
      return (
        <div className="">
          <img alt="avatar-store" src={avatar} className={styles.avatar} />
        </div>
      );
    }
    if (
      checkTypeAllowed({
        curType:
          convertFileTypeFromUrl(form.getFieldValue("avatar")?.url) ||
          convertFileTypeFromUrl(form.getFieldValue("avatar")?.name) ||
          "",
        allowedTypes: ["image/tiff"],
      })
    ) {
      return (
        <div
          className={classNames([
            styles.imgWrapper__tiff,
            styles.avatarWrongFormat,
          ])}
        >
          <TIFFViewer tiff={avatar} />
        </div>
      );
    }
    return (
      <div className={styles.avatarWrongFormat}>
        <img alt="avatar-user" src={avatar} className={styles.avatar} />
      </div>
    );
  };

  const renderListFilesUploader = (keyString: string) => {
    let acceptTypes = [...acceptedImageType, ...acceptedVideoType];
    if (keyString === "registrationPermitFiles") {
      acceptTypes = [...acceptedDocumentsType, ...acceptedImageType];
    }
    return (
      <Form.Item name={keyString}>
        <ImageUploader
          filesListFromProps={form
            ?.getFieldValue(keyString)
            ?.map((item: any) => {
              if (item?.id) {
                return {
                  ...item,
                  file: {
                    name: item?.file?.name,
                    type:
                      item?.type ||
                      convertFileTypeFromUrl(item?.url) ||
                      item?.file?.type,
                    size: item?.file?.size,
                  },
                };
              }
              return item;
            })}
          type="uploadPhoto"
          aspect={"3:4"}
          limitMaxImage={10}
          onFileChange={(data: any) => {
            const dataFile = data?.files || data;
            if (dataFile?.length >= 0) {
              form.setFieldsValue({ [keyString]: dataFile });
              const errorTextsWithValue = dataFile?.filter(
                (fileItem: any) => !!fileItem?.errorText
              );
              setGeneralErrorText({
                ...generalErrorText,
                [keyString]:
                  !errorTextsWithValue || errorTextsWithValue?.length === 0
                    ? ""
                    : i18next.t("errorText.generalUploadImageVideoDanger"),
              });
            }
          }}
          acceptedFilesTypeArr={acceptTypes}
          defaultText={t("addUserText.fileSelection")}
          itemSizeWidth={48}
          itemSizeHeight={64.656}
        />
        <div className={styles.videoDes}>{t("addUserText.noteImage")}</div>
        <div>
          {(generalErrorText as any)?.[keyString] && (
            <div className={styles.txtError}>
              {(generalErrorText as any)?.[keyString]}
            </div>
          )}
        </div>
      </Form.Item>
    );
  };

  /**
   *
   * END CONDITIONAL RENDERING
   *
   */

  useEffect(() => {
    if (firstTimeRender) {
      setFirstTimeRender(false);
    } else {
      form.validateFields(["avatar"]);
    }
    return () => {
      if (avatar) {
        window.URL.revokeObjectURL(avatar);
      }
    };
  }, [avatar]);

  return (
    <div className={styles.container}>
      {(mutation.isLoading || loadingDetailUser || isConvertingHeic) && (
        <Loading />
      )}
      <div className={styles.header}>
        <div className={styles.back}>
          <ArrowLeft
            onClick={() => {
              navigate(-1);
            }}
          />
          <span>{renderTitle()}</span>
        </div>
        <div className={styles.actions}>
          <CustomButton
            title={t("button.save")}
            className={styles.saveBtn}
            onClick={() => {
              if (
                !generalErrorText?.files &&
                !generalErrorText?.registrationPermitFiles
              ) {
                form.submit();
              }
            }}
          />
        </div>
      </div>
      <div className={styles.content}>
        <Form
          onFinish={mutation.mutateAsync}
          form={form}
          className={styles.contForms}
        >
          <div className={styles.storeInfo}>
            <div className={styles.infoTitle}>
              {t("addStoreModalText.infoTitle")}
            </div>
            {/************************************************************************/}
            {/****************************** Phone + Mail ****************************/}
            {/************************************************************************/}
            <div className={styles.infoRow}>
              <div className="f-1 mr-20">
                <LabelInput title={t("addUserText.phone")} isRequired />
                <Form.Item
                  name="phone"
                  rules={[commonValidate.required, commonValidate.phone]}
                  validateFirst
                >
                  <Input
                    className="inputCustom"
                    placeholder={t("addUserText.phone")}
                  />
                </Form.Item>
                <div className={styles.notePhone}>
                  {t("addUserText.notePhone")}
                </div>
              </div>
              <div className="f-1">
                <LabelInput title={t("addUserText.mail")} />
                <Form.Item
                  name="email"
                  rules={[commonValidate.email, commonValidate.whiteSpace]}
                  validateFirst
                >
                  <Input
                    className="inputCustom"
                    placeholder={t("addUserText.mail")}
                    maxLength={50}
                    autoComplete="new-password"
                  />
                </Form.Item>
              </div>
            </div>
            {/************************************************************************/}
            {/****************************** Password *********************************/}
            {/************************************************************************/}
            {formType === EFormUser.CREATE && (
              <div className={styles.infoRowPassword}>
                <div className="f-1">
                  <LabelInput title={t("addUserText.password")} isRequired />
                  <Form.Item
                    name="password"
                    rules={[
                      commonValidate.required,
                      commonValidate.password,
                      commonValidate.whiteSpace,
                    ]}
                    validateFirst
                  >
                    <Input.Password
                      className="inputCustom"
                      autoComplete="new-password"
                      placeholder={t("addUserText.placeholderPassword")}
                      iconRender={(visible: boolean) =>
                        visible ? <IconEye /> : <IconEyeOff />
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            )}
            {/************************************************************************/}
            {/**************************** Nick name + Experience ****************/}
            {/************************************************************************/}
            <div className={styles.infoRow}>
              <div className="f-1 mr-20">
                <LabelInput title={t("addUserText.nickName")} isRequired />
                <Form.Item
                  name="nickname"
                  rules={[commonValidate.required, commonValidate.whiteSpace]}
                  validateFirst
                >
                  <Input
                    className="inputCustom"
                    placeholder={t("addUserText.placeholderNickName")}
                    maxLength={50}
                  />
                </Form.Item>
              </div>
              <div className="f-1">
                <LabelInput title={t("addUserText.experience")} />
                <Form.Item name="experiences">
                  <TreeSelect
                    suffixIcon={<img src={images.arrowBottomIcon} />}
                    placeholder={t("addUserText.placeholderExperience")}
                    treeData={treeExperience}
                    className="tree-select"
                    treeCheckable
                    treeDefaultExpandAll={true}
                    maxTagCount={"responsive"}
                    showSearch={false}
                    showArrow
                  />
                </Form.Item>
              </div>
            </div>
            {/************************************************************************/}
            {/**************************** Date Of Birth + Cup Size ***************************/}
            {/************************************************************************/}
            <div className={styles.infoRow}>
              <div className="f-1 mr-20">
                <LabelInput title={t("addUserText.dateOfBirth")} isRequired />
                <Form.Item
                  name="dateOfBirth"
                  rules={[commonValidate.required]}
                  validateFirst
                >
                  <DatePicker
                    className="datePickerCustom"
                    placeholder={t("addUserText.dateOfBirth")}
                    suffixIcon={<Icon component={() => <DateIcon />} />}
                    inputReadOnly
                    disabledDate={(currentDate: any) => {
                      const yearDiff = dayjs().diff(currentDate, "years", true);
                      return yearDiff < 18;
                    }}
                  />
                </Form.Item>
              </div>
              <div className="f-1">
                <LabelInput title={t("addUserText.cupSize")} />
                <Form.Item name="cupSizeId">
                  <Select
                    options={listCupSizeRequest?.data?.map((item: any) => {
                      return { value: item?.id, label: item?.value };
                    })}
                    className="selectCustom"
                    suffixIcon={<img src={images.arrowBottomIcon} />}
                    placeholder={t("addUserText.cupSize")}
                  />
                </Form.Item>
              </div>
            </div>

            {/************************************************************************/}
            {/****************************** Height + Weight ****************************/}
            {/************************************************************************/}
            {/* <div className={styles.infoRowPassword}>
              <div className="f-1 mr-20">
                <LabelInput title={t("addUserText.height")} />
                <Form.Item name="height">
                  <NumericFormat
                    className={classNames([
                      "inputCustom",
                      styles.customNumeric,
                    ])}
                    maxLength={3}
                    placeholder={t("addUserText.height")}
                  />
                  <span className={styles.suffix}>cm</span>
                </Form.Item>
              </div>
              <div className="f-1">
                <LabelInput title={t("addUserText.weight")} />
                <Form.Item name="weight">
                  <NumericFormat
                    className={classNames([
                      "inputCustom",
                      styles.customNumeric,
                    ])}
                    maxLength={3}
                    placeholder={t("addUserText.weight")}
                  />
                  <span className={styles.suffix}>kg</span>
                </Form.Item>
              </div>
              
            </div> */}
            <div className={styles.infoRowPassword}>
              <div className="f-1 mr-20">
                <LabelInput title={t("addUserText.height")} />
                <Form.Item name="height">
                  <Input
                    className="inputCustom"
                    autoComplete="off"
                    placeholder={t("addUserText.height")}
                    suffix="cm"
                    maxLength={3}
                    type="number"
                  />
                </Form.Item>
              </div>
              <div className="f-1">
                <LabelInput title={t("addUserText.weight")} />
                <Form.Item name="weight">
                  <Input
                    className="inputCustom"
                    autoComplete="off"
                    placeholder={t("addUserText.weight")}
                    suffix="kg"
                    type="number"
                    maxLength={3}
                  />
                </Form.Item>
              </div>
            </div>

            {/************************************************************************/}
            {/****************************** Residence *********************************/}
            {/************************************************************************/}

            <div className={styles.infoRow}>
              <div className="f-1">
                <LabelInput title={t("addUserText.area")} isRequired />
                <Form.Item name="areaId" rules={[commonValidate.required]}>
                  <Select
                    options={listPrefecture?.items?.map((item: any) => {
                      return { value: item?.id, label: item?.value };
                    })}
                    className="selectCustom"
                    suffixIcon={<img src={images.arrowBottomIcon} />}
                    placeholder={t("addUserText.area")}
                  />
                </Form.Item>
              </div>
            </div>

            <div className={styles.infoRow}>
              <div className="f-1">
                <LabelInput title={t("addUserText.residence")} />
                <Form.Item name="residence" rules={[commonValidate.whiteSpace]}>
                  <Input
                    className="inputCustom"
                    placeholder={t("addUserText.placeHolderResidence")}
                    maxLength={250}
                  />
                </Form.Item>
              </div>
            </div>

            {/************************************************************************/}
            {/****************************** ID card *********************************/}
            {/************************************************************************/}
            <div className="mt-10" style={{ marginTop: 20 }}>
              <LabelInput title={t("addUserText.primaryIdType")} isRequired />
              <Form.Item name="primaryIdType" rules={[commonValidate.required]}>
                <Radio.Group
                  className="radioCustom"
                  onChange={(data: any) => {
                    if (data?.target?.value !== PrimaryIdType.CARD_ID) {
                      form.setFieldsValue({
                        additionalIdType: undefined,
                      });
                    }
                    form.setFieldsValue({
                      primaryIdType: data?.target?.value,
                    });
                  }}
                >
                  {listIDCard?.map((item: any) => {
                    return (
                      <Radio value={item.id} key={item.id}>
                        {item?.value}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </div>

            {typeof primaryIdCurrent === "number" &&
              primaryIdCurrent === PrimaryIdType.CARD_ID && (
                <div className="mt-10" style={{ marginTop: 20 }}>
                  <LabelInput
                    title={t("addUserText.additionalIdType")}
                    isRequired
                  />
                  <Form.Item
                    name="additionalIdType"
                    rules={[commonValidate.required]}
                  >
                    <Checkbox.Group
                      options={listIDCard
                        ?.find(
                          (item: any) => item?.id === PrimaryIdType.CARD_ID
                        )
                        ?.children?.map((item: any) => {
                          return {
                            value: item?.id,
                            label: item?.value,
                          };
                        })}
                      className={styles.checkboxGroup}
                    />
                  </Form.Item>
                </div>
              )}

            {/************************************************************************/}
            {/*********************** Start time + End time **************************/}
            {/************************************************************************/}
            <LabelInput title={t("userDetailText.timeWork")} isRequired />
            <div className={styles.infoRowStartEndTime}>
              <div className="f-1">
                <LabelInput title={t("addUserText.startTime")} isRequired />
                <Form.Item
                  name="desiredStartTime"
                  rules={[commonValidate.required]}
                  validateFirst
                  dependencies={["desiredEndTime"]}
                >
                  <TimePicker
                    onChange={() => {
                      form.validateFields(["endTime"]);
                    }}
                    className="timePickerCustom"
                    popupClassName="timePickerPopup"
                    suffixIcon={<img src={images.clockIcon} />}
                    placeholder={t("placeholder.time")}
                    format={"HH:mm"}
                    showNow={false}
                    minuteStep={30}
                    inputReadOnly
                  />
                </Form.Item>
              </div>
              <div className={styles.iconTimeDivider}>~</div>
              <div className="f-1">
                <LabelInput title={t("addUserText.endTime")} isRequired />
                <Form.Item
                  name="desiredEndTime"
                  validateFirst
                  rules={[
                    commonValidate.required,
                    ({ getFieldValue }: any) => ({
                      validator(_: any, value: any) {
                        const desiredStartTimeCurrent =
                          getFieldValue("desiredStartTime");
                        if (value) {
                          return Promise.resolve();
                        } else if (!!desiredStartTimeCurrent) {
                          return Promise.reject(
                            new Error(t("validate.fieldIsRequired"))
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  dependencies={["desiredStartTime"]}
                >
                  <TimePicker
                    className="timePickerCustom"
                    popupClassName="timePickerPopup"
                    suffixIcon={<img src={images.clockIcon} />}
                    placeholder={t("placeholder.time")}
                    format={"HH:mm"}
                    showNow={false}
                    minuteStep={30}
                    inputReadOnly
                  />
                </Form.Item>
              </div>
            </div>
            {/************************************************************************/}
            {/********************************* Pick up Request ***************************/}
            {/************************************************************************/}
            <div className="mt-10" style={{ marginTop: 20 }}>
              <LabelInput title={t("addUserText.pickupRequest")} />
              <Form.Item name="pickUpRequestId">
                <Radio.Group
                  className="radioCustom"
                  onChange={(data: any) => {
                    form.setFieldsValue({
                      pickUpRequestId: data?.target?.value,
                    });
                  }}
                >
                  {listPickupRequest?.data?.map((item: any) => {
                    return (
                      <Radio value={item.id} key={item.id}>
                        {item?.value}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </div>
            {/************************************************************************/}
            {/****************************** Desired place of delivery *********************************/}
            {/************************************************************************/}
            <div className={styles.infoRow}>
              <div className="f-1">
                <LabelInput title={t("addUserText.placeDelivery")} />
                <Form.Item
                  name="desiredPickUpPlace"
                  rules={[commonValidate.whiteSpace]}
                >
                  <Input
                    className="inputCustom"
                    placeholder={t("addUserText.placeHolderPlaceDelivery")}
                    maxLength={250}
                  />
                </Form.Item>
              </div>
            </div>

            {/************************************************************************/}
            {/********************************* Panel ***************************/}
            {/************************************************************************/}
            <div className="mt-10" style={{ marginTop: 20 }}>
              <LabelInput title={t("addUserText.panel")} />

              <Form.Item name="panelId">
                <Radio.Group
                  defaultValue={panelId}
                  className={classNames(["radioCustom", styles.radio])}
                  onChange={(data: any) => {
                    form.setFieldsValue({ panelId: data?.target?.value });
                  }}
                >
                  {listPanelRequest?.data?.map((item: any) => {
                    return (
                      <Radio
                        value={item.id}
                        key={item.id}
                        checked={item.id === panelId}
                      >
                        {item.value}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </div>
            {/************************************************************************/}
            {/***************************** PR ****************************/}
            {/************************************************************************/}
            <div className={styles.infoRow}>
              <div className="f-1">
                <LabelInput title={t("addUserText.pr")} />
                <Form.Item name="selfPR">
                  <Input.TextArea
                    showCount
                    maxLength={1000}
                    className="textAreaCustom"
                    placeholder={t("addUserText.placeholderPr")}
                  />
                </Form.Item>
              </div>
            </div>

            {/************************************************************************/}
            {/************************** 10 Images/Videos ****************************/}
            {/************************************************************************/}
            <div className="mt-20">
              <div className="f-1">
                <LabelInput title={t("addStoreModalText.video")} />
                {renderListFilesUploader("files")}
              </div>
            </div>
          </div>
          {/************************************************************************/}
          {/******************************* Upload Avatar **************************/}
          {/************************************************************************/}
          <div className={styles.settingIcon}>
            <div className={classNames([styles.iconTitle])}>
              {t("addUserText.iconEdit")}
            </div>
            <div className={styles.showAvatar}>{renderAvatarComponent()}</div>
            <div className={classNames(styles.iconDes, "mt-15")}>
              <span>{t("addStoreModalText.limitIcon10mb")}</span>
              <span>{t("addStoreModalText.limitIconType")}</span>
              <Form.Item
                name="avatar"
                style={{ marginTop: -30 }}
                rules={RuleForm.avatarNotRequire()}
              ></Form.Item>
            </div>
            <div className={styles.iconAction}>
              {formType === EFormUser.UPDATE && (
                <CustomButton
                  title={t("button.delete")}
                  type={ButtonType.OUTLINE}
                  className={styles.deleteIcon}
                  onClick={() => {
                    window.URL.revokeObjectURL(avatar);
                    setAvatar(undefined);
                    form.setFieldsValue({ avatar: undefined });
                  }}
                />
              )}

              {/* <ImgCrop modalTitle={t("common.editImage")} cropShape={"round"}> */}
              <Upload
                accept={acceptedAvatarType?.join(", ")}
                showUploadList={false}
                customRequest={() => null}
                beforeUpload={async (file) => {
                  if (file?.type !== "image/gif") {
                    setRawFileList([file]);
                    setOpenModal(true);
                  } else {
                    if (
                      !RegExp(/(heic|heif)/i).test(`${file.type} ${file?.name}`)
                    ) {
                      const urlAvatar = window.URL.createObjectURL(file);
                      setAvatar(urlAvatar);
                      form.setFieldsValue({ avatar: file });
                      return;
                    }
                    const conversionResult = await convertHeicToAny(file);
                    const jpegFile = conversionResult?.fileAfterProcessed;
                    const urlAvatar = conversionResult?.urlImage;
                    setAvatar(urlAvatar);
                    form.setFieldsValue({ avatar: jpegFile });
                  }
                }}
              >
                <CustomButton
                  title={t("button.upload")}
                  className={
                    formType === EFormUser.UPDATE
                      ? styles.uploadIcon
                      : styles.uploadIconCreate
                  }
                />
              </Upload>
            </div>
          </div>
        </Form>
      </div>
      <ModalCropImage
        open={openModal}
        aspect={"1:1"}
        listImages={rawFileList}
        cropShape={"round"}
        onFinishCrop={async (data: any) => {
          const file = data?.[0];
          if (!RegExp(/(heic|heif)/i).test(`${file.type} ${file?.name}`)) {
            const urlAvatar = window.URL.createObjectURL(file);
            setAvatar(urlAvatar);
            form.setFieldsValue({ avatar: file });
            setOpenModal(false);
            setRawFileList([]);
            return;
          }
          const conversionResult = await convertHeicToAny(file);
          const jpegFile = conversionResult?.fileAfterProcessed;
          const urlAvatar = conversionResult?.urlImage;
          setAvatar(urlAvatar);
          form.setFieldsValue({ avatar: jpegFile });
          setOpenModal(false);
          setRawFileList([]);
          return;
        }}
      />
    </div>
  );
}

import { getRefreshToken } from "api/axios";
import socket from "api/socket";
import { getPresignedUrl } from "api/upload";
import axios from "axios";
import CustomNotification from "components/CustomNotification";
import configs from "constants/config";
import dayjs, { Dayjs } from "dayjs";
import { t } from "i18next";
import Cookies from "js-cookie";
import { isArray, isEmpty, throttle } from "lodash";
import {
  acceptedDocumentsType,
  acceptedImageType,
  acceptedVideoType,
  dataDate,
} from "./const";
import {
  Ability,
  DateOption,
  EFormStore,
  EFormUser,
  FeatureManagement,
  FormatTime,
  NotificationType,
  PostStatus,
  Roles,
  SocketEvent,
  StatusPost,
  UnitType,
  WebType,
  activeType,
  applicationStatus,
} from "./enum";
import {
  IFilterQuery,
  InputObject,
  ListGroup,
  ListItem,
  YearGroup,
} from "./interface";
import { REGEX_IMAGE_URI, REGEX_PHONE_MAX } from "./regex";
import queryString from "query-string";

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.message || "不明なエラーが発生しました。";
};

export const handleErrorMessage = (error: any) => {
  CustomNotification({
    type: "error",
    message: t("common.error"),
    description: getErrorMessage(error),
  });
  if (configs.APP_ENV !== "prod") {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getIndexTable = (
  pageIndex: number,
  pageSize: number,
  current: number
) => {
  return (pageIndex - 1) * pageSize + current + 1;
};

export const getBase64 = (img: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });

export const convertTimeToLocal = (time?: string, format?: string) => {
  if (!time) return "";
  return dayjs(time)
    .format(format || FormatTime.DATE_TIME)
    .toString();
};

export const convertTimeToUTC = (time?: Dayjs | null, option?: DateOption) => {
  if (!time) return undefined;
  if (option === DateOption.START_DATE) {
    return time?.startOf("date")?.toISOString();
  }
  if (option === DateOption.END_DATE) {
    return time?.endOf("date")?.toISOString();
  }
  return time?.toISOString();
};

export const convertTimeToUTCMonth = (
  time?: Dayjs | null,
  option?: DateOption
) => {
  if (!time) return undefined;
  if (option === DateOption.START_DATE) {
    return time?.startOf("month")?.toISOString();
  }
  if (option === DateOption.END_DATE) {
    return time?.endOf("month")?.toISOString();
  }
  return time?.toISOString();
};

export const convertJaMoney = (money?: number) => {
  if (money === 0) {
    return "￥0";
  }
  if (!money) return "";
  return "￥" + money.toLocaleString("ja-JP");
};

export const convertPoint = (money?: number) => {
  if (money === 0) {
    return "0";
  }
  if (!money) return "";
  return money.toLocaleString("ja-JP");
};

export const convertJaMoneyTenThousandSuffix = (money?: number) => {
  if (money === 0) {
    return "0万円";
  }
  if (!money) return "";
  return `${money.toLocaleString("ja-JP")}万円`;
};

export const convertJaMoneyTenThousand = (money?: number) => {
  if (!money) return "";
  return `${(money * 10000).toLocaleString("ja-JP")}`;
};

export const convertJaMoneyMan = (money?: number) => {
  if (!money) return "";
  return `${money.toLocaleString("ja-JP")}`;
};

export const convertJaMoneyManSuffix = (money?: number) => {
  if (money === 0) {
    return "0円";
  }
  if (!money) return "";
  return `${money.toLocaleString("ja-JP")}円`;
};

export const convertUnit = (type: UnitType, value?: number) => {
  if (!value) return "";
  let result = "";

  switch (type) {
    case UnitType.AGE:
      result = `${value}${t("common.age")}`;
      break;
    case UnitType.HEIGHT:
      result = `${value}cm`;
      break;
    case UnitType.WEIGHT:
      result = `${value}kg`;
      break;
    case UnitType.PERCENT:
      result = `${value}%`;
      break;
    default:
      break;
  }
  return result;
};

export const convertHoursToDate = (hourString?: string) => {
  if (!hourString) return null;
  const hourList = hourString.split(":");
  return dayjs().hour(parseInt(hourList[0])).minute(parseInt(hourList[1]));
};

export const convertBooleanToString = (value?: boolean) => {
  if (typeof value !== "boolean") return "";
  if (value) return t("booleanText.true");
  return t("booleanText.false");
};

export const convertFileTypeFromUrl = (url?: string) => {
  if (!url) return null;
  const urlArrSplit = url?.split(".");
  const simpleTypeFromUrl = urlArrSplit[urlArrSplit?.length - 1]?.toLowerCase();
  // Image
  const acceptedImageTypeWithoutPrefix = acceptedImageType.map((item) =>
    item.split("/")[1].toLowerCase()
  );
  if (acceptedImageTypeWithoutPrefix?.includes(simpleTypeFromUrl)) {
    return acceptedImageType[
      acceptedImageTypeWithoutPrefix?.indexOf(simpleTypeFromUrl)
    ];
  }
  // Video
  const acceptedVideoTypeWithoutPrefix = acceptedVideoType.map((item) =>
    item.split("/")[1].toLowerCase()
  );
  if (acceptedVideoTypeWithoutPrefix?.includes(simpleTypeFromUrl)) {
    return acceptedVideoType[
      acceptedVideoTypeWithoutPrefix?.indexOf(simpleTypeFromUrl)
    ];
  }
  // Document
  const acceptedDocumentTypeWithoutPrefix = acceptedDocumentsType.map((item) =>
    item.split("/")[1].toLowerCase()
  );
  if (acceptedDocumentTypeWithoutPrefix?.includes(simpleTypeFromUrl)) {
    return acceptedDocumentsType[
      acceptedDocumentTypeWithoutPrefix?.indexOf(simpleTypeFromUrl)
    ];
  }
  return null;
};

export const getDataWithoutCondition = ({
  data,
  conditions,
}: {
  data: any;
  conditions: Array<any>;
}) => {
  return conditions?.includes(data) ? undefined : data;
};

export const handleIndexRoutes = (
  routes: Array<{
    text: any;
    icon: any;
    url?: string;
    visible: boolean;
    children?: Array<{
      text: any;
      url: string;
      visible: boolean;
      icon?: any;
    }>;
  }>
) => {
  const newRoutes = routes?.map((routeItem, routeIndex: number) => {
    return {
      ...routeItem,
      key: `${routeIndex + 1}`,
      children: routeItem?.children
        ? routeItem?.children?.map((childItem, childIndex) => {
            return {
              ...childItem,
              key: `${routeIndex + 1}.${childIndex + 1}`,
            };
          })
        : undefined,
    };
  });
  return newRoutes;
};

export const checkNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
};

export const checkTypeAllowed = ({
  curType,
  allowedTypes,
}: {
  curType: string;
  allowedTypes: Array<string>;
}) => {
  for (let allowItem of allowedTypes) {
    if (RegExp(allowItem).test(curType)) {
      return true;
    }
  }
  return allowedTypes.includes(curType);
};

/**
 *  Pre-sign list files before call API CRUD store
 */
export const handlePreSignListFiles = async ({
  keyString,
  payload,
}: {
  keyString: string;
  payload: any;
}) => {
  let presignedFiles;
  const payloadsFilesToUpload = {
    [keyString]: payload?.[keyString]?.filter(
      (file: any) => !!file?.file?.name
    ),
  };
  if (payloadsFilesToUpload?.[keyString]?.length > 0) {
    presignedFiles = await getPresignedUrl(
      payloadsFilesToUpload?.[keyString]?.map((file: any) => {
        return {
          fileName: file.file.name,
          path:
            keyString === "registrationPermitFiles"
              ? "registration-permit-files"
              : "attachment",
        };
      })
    );

    await Promise.all(
      presignedFiles?.data?.map(async (item: any, index: number) => {
        try {
          await axios({
            method: "PUT",
            url: item.presignedUrl,
            data: payloadsFilesToUpload?.[keyString]?.[index]?.file,
            headers: {
              "Content-Type":
                payloadsFilesToUpload?.[keyString]?.[index]?.file?.type,
            },
          });
        } catch (err) {
          handleErrorMessage(err);
        }
      })
    );
  }

  const filesToUpload = [
    ...(payload?.[keyString]
      ? payload?.[keyString]?.filter((file: any) => !file?.file?.name)
      : []),
    ...(presignedFiles?.data?.map((item: any) => {
      return {
        ...item,
        path: item?.fileName,
      };
    }) || []),
  ];
  return filesToUpload?.map((item, index) => {
    return {
      id: item?.id,
      path: item?.path,
      size:
        item?.size === null
          ? undefined
          : item?.size || payload?.[keyString]?.[index]?.file?.size,
      type:
        item?.type === null
          ? undefined
          : item?.type || payload?.[keyString]?.[index]?.file?.type,
    };
  });
};

/**
 *  Pre-sign avatar before call API CRUD store
 */
export const handlePreSignAvatar = async ({
  payload,
  formType,
  avatarFromApi,
}: {
  payload: any;
  formType: EFormStore | EFormUser;
  avatarFromApi: any;
}) => {
  let avatarPathname;
  let curAvatarId;
  if (!payload.avatar)
    return {
      avatarPathname: undefined,
    };
  else if (!!payload.avatar) {
    if (payload?.avatar?.name) {
      const presignedAvatar = await getPresignedUrl([
        {
          fileName: payload.avatar.name,
          path: "attachment",
        },
      ]);
      await axios({
        method: "PUT",
        url: presignedAvatar.data[0].presignedUrl,
        data: payload.avatar,
        headers: {
          "Content-Type": payload.avatar.type,
        },
      });
      avatarPathname = presignedAvatar?.data[0]?.fileName;
    } else {
      avatarPathname = payload?.avatar?.path;
      if (formType !== EFormStore.CREATE) {
        curAvatarId = avatarFromApi?.avatar?.id;
      }
    }
  }
  return {
    avatarPathname,
    curAvatarId,
    type: payload.avatar.type,
  };
};

export const handleUploadFileSingle = async (file: File) => {
  const presignedAvatar = await getPresignedUrl([
    {
      fileName: file.name,
      path: "attachment",
    },
  ]);
  await axios({
    method: "PUT",
    url: presignedAvatar.data[0].presignedUrl,
    data: file,
    headers: {
      "Content-Type": file.type,
    },
  });
  return {
    path: presignedAvatar?.data[0]?.fileName,
    type: file.type,
    size: file.size,
  };
};

export const formatYearMonthDay = (dateString: string) =>
  dayjs(dateString).format("YYYY/MM/DD");

export const getTypeNotification = (type: WebType) => {
  if (type === WebType.CANDIDATE) {
    return t("option.candidate");
  }
  return t("option.business");
};

export const templateNotificationType: { [key in NotificationType]?: string } =
  {
    [NotificationType.APPROVE_JOB_FOR_CANDIDATE]: "マッチされた時",
    [NotificationType.NEW_RECRUITMENT_POST_FOR_CANDIDATE_SUBSCRIBED]:
      "お気に入り店舗が募集開始し出した時",
    [NotificationType.NEW_BOX_CHAT_FOR_CANDIDATE]:
      "新しいチャットボックスがあった時",
    [NotificationType.STORE_REVIEW_CANDIDATE]: "評価された時",
    [NotificationType.REPORT_FOR_CANDIDATE]: "店舗から通報された時",
    [NotificationType.REMIND_CANDIDATE_REVIEW_STORE]:
      "店舗にレビューをすると推奨する時",
    [NotificationType.BLOCK_CANDIDATE]: "利用停止された時",
    [NotificationType.NOT_PASS_RECRUITMENT_POST_FOR_CANDIDATE]:
      "マッチされなかったとき",
    [NotificationType.RECRUITMENT_POST_APPLY_DELETED_FOR_CANDIDATE]:
      "応募した投稿が削除された時",
    [NotificationType.NEW_BOX_CHAT_FOR_STORE]:
      "新規チャットボックスがある時の通知",
    [NotificationType.CANDIDATE_APPLIED_RECRUITMENT_POST]:
      "応募したキャストがある時の通知",
    [NotificationType.CANDIDATE_CANCEL_CALENDAR]:
      "キャストがスケジュールをキャンセルした時の通知",
    [NotificationType.REMIND_STORE_REVIEW_CANDIDATE]:
      "キャストのレビューがある時の通知",
    [NotificationType.CANDIDATE_REVIEW_STORE]: "キャストがレビューした時の通知",
    [NotificationType.CANDIDATE_REACT_STORE_PROFILE]:
      "キャストにプロフィールが気に入った時の通知",
    [NotificationType.BLOCK_STORE]: "ブロックされたときの通知",
    [NotificationType.EXTEND_PLAN_FOR_STORE]:
      "（登録中のプランの有効期限が近づく）プラン更新の通知",
    [NotificationType.PLAN_EXPIRED_FOR_STORE]:
      "（登録中のプランの有効期限が切れた時）プラン更新の通知",
  };

function formatFalsyToUndefined(obj: any): Partial<any> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = value ?? undefined;
    return acc;
  }, {} as Partial<any>);
}

export const trimFieldStringObject = (data: { [key: string]: any }) => {
  const dataClone = { ...data };
  Object.keys(data)?.forEach((item: string) => {
    if (typeof dataClone[item] === "string") {
      dataClone[item] = dataClone[item].trim();
    }
  });
  return formatFalsyToUndefined(dataClone);
};

export const convertPhone = (phone: string) => {
  if (!phone) {
    return "";
  }
  const phoneConvert = phone?.match(REGEX_PHONE_MAX)
    ? `0${phone?.slice(3, 5)} - ${phone?.slice(5, 9)} - ${phone.slice(9)}`
    : `0${phone?.slice(3, 5)} - ${phone?.slice(5, 9)} - ${phone?.slice(9)}`;
  return phoneConvert;
};

export const convertPhoneOtherSetting = (phone: string) => {
  if (!phone) {
    return "";
  }
  if (phone?.match(REGEX_PHONE_MAX)) {
    return `0${phone?.slice(3)}`;
  } else return phone;
};

export const getTimeTextPost = (
  startAt: string,
  businessHours: number,
  isLastLabelShown: boolean
) => {
  const startTime = dayjs(startAt).format("HH:mm");
  const endTime = isLastLabelShown
    ? "LAST"
    : dayjs(startAt).add(businessHours, "hour").format("HH:mm");
  return `${startTime}-${endTime}`;
};

export const dateUtils = {
  getCurrentMonthYear: (date: any) => dayjs(date).format("YYYY年MM月"),
  weekdaysArr: [0, 1, 2, 3, 4, 5, 6],
  getDayOfWeek: (daySelected: any, dayNumber: number) =>
    dayjs(daySelected).day(dayNumber),
  getDayOfCurrentWeek: (date: any, index: number) => dayjs(date).day(index),
  getCurrentDay: () => dayjs(),
  checkIsSameDay: (daySelected: any, currentDay: any) =>
    dayjs(daySelected).isSame(currentDay, "day"),
  getYearMonthDate: (date: string) => dayjs(date).format("YYYY年MM月DD日"),
  getYearMonthDateHourMinuteContact: (date: string) =>
    dayjs(date).format("YYYY年MM月DD日HH:mm"),
  getYearMonthDateHourMinute: (date: string) =>
    dayjs(date).format("HH:mm - YYYY年MM月DD日"),

  isBeforeNow: (date: string | any) => dayjs().isBefore(dayjs(date)),
  getMonthDate: (date: string) => dayjs(date).format("MM月DD日"),
  getBirthday: (date: string) => dayjs(date).format("YYYY/MM/DD"),
  getDateCreate: (date: string) => dayjs(date).format("YYYY/MM/DD HH:mm"),
};
export const rolesText: { [key in FeatureManagement]?: string } = {
  [FeatureManagement.EMPLOYER_LIST_MANAGEMENT]: "スタッフのアカウント管理",
  [FeatureManagement.EMPLOYER_ROLE_MANAGEMENT]: "スタッフの権限管理",
  [FeatureManagement.USER_ACCOUNT_MANAGEMENT]: "ユーザーのアカウント管理",
  [FeatureManagement.STORE_ACCOUNT_MANAGEMENT]: "店舗のアカウント管理",
  [FeatureManagement.APPROVAL_STORE_ACCOUNT]: "新規店舗の申し込みを承認",
  [FeatureManagement.POSTS_MANAGEMENT]: "投稿管理",
  [FeatureManagement.NOTIFICATION]: "手動作成通知管理",
  [FeatureManagement.EDIT_NOTIFICATION]: "自動送信通知管理",
  [FeatureManagement.REQUEST_PLAN]: "プランを申し込み",
  [FeatureManagement.LIST_PLAN]: "プラン一覧",
  [FeatureManagement.CONTRACT_MANAGEMENT]: "お問い合わせ管理",
  [FeatureManagement.BUG_MANAGEMENT]: "不具合報告管理",
  [FeatureManagement.SETTING]: "設定 ",
  [FeatureManagement.MASTER_DATA]: "マスターデータ ",
};

export const rolesPermission: {
  [key in FeatureManagement]?: { title: string; value: Ability }[];
} = {
  [FeatureManagement.EMPLOYER_LIST_MANAGEMENT]: [
    {
      title: "追加",
      value: Ability.CREATE,
    },
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "編集",
      value: Ability.EDIT,
    },
    {
      title: "アカウントをロック・ロック削除",
      value: Ability.ON_OFF,
    },
  ],
  [FeatureManagement.EMPLOYER_ROLE_MANAGEMENT]: [
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "編集",
      value: Ability.EDIT,
    },
  ],
  [FeatureManagement.USER_ACCOUNT_MANAGEMENT]: [
    {
      title: "追加",
      value: Ability.CREATE,
    },
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "編集",
      value: Ability.EDIT,
    },
  ],
  [FeatureManagement.STORE_ACCOUNT_MANAGEMENT]: [
    {
      title: "追加",
      value: Ability.CREATE,
    },
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "編集",
      value: Ability.EDIT,
    },
    {
      title: "振込を作成",
      value: Ability.CREATE_PAYMENT,
    },
    {
      title: "プランをON・OFFにする",
      value: Ability.ON_OFF,
    },
  ],
  [FeatureManagement.APPROVAL_STORE_ACCOUNT]: [
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "アカウントを作成",
      value: Ability.CREATE,
    },
    {
      title: "削除",
      value: Ability.DELETE,
    },
  ],
  [FeatureManagement.POSTS_MANAGEMENT]: [
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "編集",
      value: Ability.EDIT,
    },
    {
      title: "削除",
      value: Ability.DELETE,
    },
  ],
  [FeatureManagement.NOTIFICATION]: [
    {
      title: "通知を作成",
      value: Ability.CREATE,
    },
  ],
  [FeatureManagement.EDIT_NOTIFICATION]: [
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "通知の内容を編集",
      value: Ability.EDIT,
    },
  ],
  [FeatureManagement.REQUEST_PLAN]: [
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "申し込みを承認",
      value: Ability.APPROVAL,
    },
  ],
  [FeatureManagement.LIST_PLAN]: [
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "編集",
      value: Ability.EDIT,
    },
  ],
  [FeatureManagement.CONTRACT_MANAGEMENT]: [
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
  ],
  [FeatureManagement.BUG_MANAGEMENT]: [
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
  ],
  [FeatureManagement.SETTING]: [
    {
      title: "追加",
      value: Ability.CREATE,
    },
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "編集",
      value: Ability.EDIT,
    },
    {
      title: "削除",
      value: Ability.DELETE,
    },
  ],
  [FeatureManagement.MASTER_DATA]: [
    {
      title: "追加",
      value: Ability.CREATE,
    },
    {
      title: "詳細を見る",
      value: Ability.VIEW,
    },
    {
      title: "編集",
      value: Ability.EDIT,
    },
    {
      title: "削除",
      value: Ability.DELETE,
    },
  ],
};

export const createTimeFromDateToCountdown = (date: string) =>
  new Date(date).valueOf();

export const convertStatus = (
  startAt: string,
  status: string,
  applicationDeadline: number,
  businessHours: number
) => {
  const beforeNow = dateUtils.isBeforeNow(
    dayjs(startAt).subtract(applicationDeadline, "minute")
  );
  const beforeNowHour = dateUtils.isBeforeNow(
    dayjs(startAt).add(businessHours, "hour")
  );
  if (status === StatusPost.PUBLISHED && beforeNow) {
    return PostStatus.RECRUITING;
  } else if (status === StatusPost.PUBLISHED && !beforeNow) {
    return PostStatus.EXPIRED;
  } else if (status === StatusPost.CANCEL) return PostStatus.CANCEL;
  else if (status === StatusPost.CONFIRMED && !beforeNowHour)
    //done
    return PostStatus.COMPLETE;
  return PostStatus.RECRUITED;
};

export const formatCurrencyJapanese = (value: number) =>
  Number(value).toLocaleString("ja-JP", { style: "currency", currency: "JPY" });

export const createQueryString = (initQuery?: Record<string, any>) => {
  if (isEmpty(initQuery)) return "";

  return queryString.stringify(initQuery);
};

export const convertQueryDataObj = (initQueryString?: string) => {
  const queryObj = queryString.parse(initQueryString || "");
  return queryObj;
};

export const convertStringElementToNumber = (data?: any[]) => {
  if (isArray(data)) {
    return data.map((item) => Number(item));
  }
  return undefined;
};

export const convertOriginFilter = (filter?: IFilterQuery) => {
  return filter;
};

export const convertFilterToValidForm = (filter?: IFilterQuery) => {
  if (filter) {
    let formDefaultValue = {};
    formDefaultValue = {
      ...formDefaultValue,
      keyword: filter?.keyword,
    };

    if (filter?.planId) {
      formDefaultValue = {
        ...formDefaultValue,
        planId: Number(filter?.planId),
      };
    }

    if (filter?.industryIds) {
      formDefaultValue = {
        ...formDefaultValue,
        industryIds: convertStringElementToNumber(filter?.industryIds),
      };
    }
    if (filter?.prefectureIds) {
      formDefaultValue = {
        ...formDefaultValue,
        prefectureIds: convertStringElementToNumber(filter?.prefectureIds),
      };
    }
    if (filter?.isDonatedPostCount) {
      formDefaultValue = {
        ...formDefaultValue,
        isDonatedPostCount: filter?.isDonatedPostCount === "true",
      };
    }
    // if (filter?.startAt) {
    //   formDefaultValue = {
    //     ...formDefaultValue,
    //     ...getValidDayjsObj({ field: "startAt", value: filter?.startAt }),
    //   };
    // }
    // if (filter?.endAt) {
    //   formDefaultValue = {
    //     ...formDefaultValue,
    //     ...getValidDayjsObj({ field: "startAt", value: filter?.startAt }),
    //   };
    // }
    if (filter?.limit) {
      formDefaultValue = {
        ...formDefaultValue,
        limit: Number(filter?.limit),
      };
    }
    if (filter?.page) {
      formDefaultValue = {
        ...formDefaultValue,
        page: Number(filter?.page),
      };
    }
    return formDefaultValue;
  }
  return null;
};

export const convertFilterPageAndLimit = (filter?: IFilterQuery) => {
  if (filter) {
    let formDefaultValue = {};
    if (filter?.limit) {
      formDefaultValue = {
        ...formDefaultValue,
        limit: Number(filter?.limit),
      };
    }
    if (filter?.page) {
      formDefaultValue = {
        ...formDefaultValue,
        page: Number(filter?.page),
      };
    }
    return formDefaultValue;
  }
  return null;
};

export const convertFilterSite = (filter?: IFilterQuery) => {
  if (filter) {
    let formDefaultValue = {};
    if (filter?.limit) {
      formDefaultValue = {
        ...formDefaultValue,
        limit: Number(filter?.limit),
      };
    }
    if (filter?.page) {
      formDefaultValue = {
        ...formDefaultValue,
        page: Number(filter?.page),
      };
    }
    if (filter?.site) {
      formDefaultValue = {
        ...formDefaultValue,
        site: filter?.site,
      };
    }
    if (filter?.keyword) {
      formDefaultValue = {
        ...formDefaultValue,
        keyword: filter?.keyword,
      };
    }
    return formDefaultValue;
  }
  return null;
};

export const fieldText: any = {
  candidate_profile_form: {
    surveys: "最初にふ〜ぷを知ったきっかけはなんですか？",
    nickname: "ニックネーム",
    phone: "電話番号",
    dateOfBirth: "生年月日",
    height: "身長",
    weight: "体重",
    cupSizeId: "カップサイズ",
    experiences: "経験",
    residence: "お住まい",
    pickUpRequestId: "送迎希望",
    desiredPickUpPlace: "送り希望場所",
    panelId: "パネル表示希望",
    selfPR: "自己PR",
    files: "動画・画像アップロード",
    description: "店舗紹介",
  },

  business_profile_form: {
    contentsOfInquiry: "お問い合わせ内容",
    prefectureId: "都道府県",
    municipalityId: "エリア",
    // desiredLocation: "ご希望の掲載地域",
    industryId: "業種",
    name: "店舗名",
    personInCharge: "担当者様名（カタカナ）",
    email: "メールアドレス",
    phone: "電話番号",
    preferredContactMethods: "希望連絡方法",
    desiredContactTime: "希望連絡時間",
    remark: "備考（ご質問・連絡事項など）",
    files: "画像/動画 バナー",
    description: "店舗紹介",
    startTime: "開始時間",
    endTime: "終了時間",
    isLastLabelShown: `終了時間を"LAST"と表記する`,
    websiteUrl: "公式HP",
    twitterUrl: "Twitter",
    accessMethod: "アクセスの方法",
    nearestStation: "最寄駅",
    desiredLocation: "住所",
  },
  recruitment_post_form: {
    title: "タイトル",
    startDate: "募集日",
    startTime: "開始時間",
    endTime: "終了時間",
    isLastLabelShown: `終了時間を"LAST"と表記する`,
    businessHours: "業務時間",
    estimatedSalary: "給料目安",
    isGuaranteedMinimum: "最低保証",
    applicationDeadlineId: "応募締め切り時間",
    treatmentIds: "待遇",
    applicationRequirements: "詳細",
    note: "注意事項",
    conditionsOfWork: "募集要項",
    inexperiencedMeetingTime: "未経験の場合",
    experiencedMeetingTime: "経験者の場合",
    belongings: "持ち物",
    identificationCard: "[必読]年齢確認/身分証",
    trainAccess: "🚃アクセス",
    carAccess: "🚗アクセス",
  },
};

export const processListPlansIntoSelect = (currentListsPlanResult: any) => {
  if (!currentListsPlanResult) return [];
  return currentListsPlanResult?.items?.map((item: any) => ({
    value: item?.id,
    label: item?.title,
    price: item?.price,
  }));
};

export const roleTextMap: { [key in Roles]: string } = {
  admin: " 管理者(admin)",
  staff: "STAFF",
  accounting: "経理",
  sales: "代理店",
};

export const checkIsImageURI = (uri: string) => {
  return RegExp(REGEX_IMAGE_URI).test(uri);
};

interface IRole {
  id: number;
  isSuperAdmin: boolean;
  name: string;
  type: "manager" | "sale";
}
export const hasViewPermission = (permissions: string[], role: IRole) =>
  permissions?.includes(Ability.VIEW) || !!role?.isSuperAdmin;

export const hasCreatePermission = (permissions: string[], role: IRole) =>
  permissions?.includes(Ability.CREATE) || !!role?.isSuperAdmin;

export const hasEditPermission = (permissions: string[], role: IRole) =>
  permissions?.includes(Ability.EDIT) || !!role?.isSuperAdmin;

export const hasDeletePermission = (permissions: string[], role: IRole) =>
  permissions?.includes(Ability.DELETE) || !!role?.isSuperAdmin;

export const hasOnOffPermission = (permissions: string[], role: IRole) =>
  permissions?.includes(Ability.ON_OFF) || !!role?.isSuperAdmin;

export const hasCreatePaymentPermission = (
  permissions: string[],
  role: IRole
) => permissions?.includes(Ability.CREATE_PAYMENT) || !!role?.isSuperAdmin;

export const hasApprovalPermission = (permissions: string[], role: IRole) =>
  permissions?.includes(Ability.APPROVAL) || !!role?.isSuperAdmin;

export const checkboxLabelMap = {
  candidate_registration_terms: "アカウント登録",
  candidate_application_terms: "応募意思確認",
  candidate_chat_terms: "チャットに関する注意事項",
  business_login_terms: "ログイン",
  business_chat_terms: "チャットに関する注意事項",
};

export const convertNameFilePdf = (name: string) => {
  const indexSlice = name?.indexOf("_");
  const tempName = name?.slice(indexSlice + 1);
  return tempName;
};

export const handleConnectSocket = throttle(
  async () => {
    if (!Cookies.get("token")) {
      await getRefreshToken();
    }
    if (socket.connected) {
      socket.disconnect();
    }
    if (Cookies.get("token")) {
      socket.auth = { accessToken: Cookies.get("token") };
      socket.connect();
    }
    socket.on(SocketEvent.CONNECT, () => {
      console.log(socket.id);
    });
    socket.on(SocketEvent.DISCONNECT, (reason: any) => {
      console.log(reason);
    });
    return () => {
      socket.off(SocketEvent.CONNECT);
      socket.off(SocketEvent.DISCONNECT);
    };
  },
  500,
  { trailing: false }
);

export function transformArray(inputArray: InputObject[]): YearGroup[] {
  if (!inputArray) return [];
  // Hàm trợ giúp để nhóm mảng theo một hàm keySelector cho trước
  const groupBy = <T, K extends keyof any>(
    array: T[],
    keySelector: (item: T) => K
  ): Record<K, T[]> => {
    return array?.reduce((accumulator, currentItem) => {
      const key = keySelector(currentItem);
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key]?.push(currentItem);
      return accumulator;
    }, {} as Record<K, T[]>);
  };

  // Bước 1: Nhóm theo năm từ startAt
  const groupedByYear = groupBy(inputArray, (item) =>
    new Date(item.recruitmentPost.startAt).getFullYear().toString()
  );

  // Chuyển đổi object đã nhóm thành mảng theo format mong muốn
  return Object.entries(groupedByYear)
    ?.map(([year, items]): YearGroup => {
      // Bước 2: Nhóm theo ngày từ startAt trong mỗi năm
      const groupedByDay = groupBy(
        items,
        (item) => item.recruitmentPost.startAt
      );

      //Sap xep truoc khi chuyen doi
      const sortedDayKeys = Object.keys(groupedByDay).sort(
        (a, b) => new Date(b).getTime() - new Date(a).getTime()
      );
      // Chuyển đổi các nhóm ngày thành mảng của list
      const dayLists: ListGroup[] = sortedDayKeys.map((day) => {
        const items = groupedByDay[day];
        const list: ListItem[] = items.map((item) => ({
          idStore: item.business.id,
          name: item.business.name,
          actionChangeValue: item.actionChangeValue,
        }));

        return { title: day, list };
      });

      return { title: year, list: dayLists };
    })
    ?.sort((a: any, b: any) => Number(b?.title) - Number(a?.title));
}

export const returnListSelected = (schedule: any) => {
  let listSelected: any[] = [];
  if (schedule?.isMonday) listSelected = [...listSelected, dataDate?.[0]?.id];
  if (schedule?.isTuesday) listSelected = [...listSelected, dataDate?.[1]?.id];
  if (schedule?.isWednesday)
    listSelected = [...listSelected, dataDate?.[2]?.id];
  if (schedule?.isThursday) listSelected = [...listSelected, dataDate?.[3]?.id];
  if (schedule?.isFriday) listSelected = [...listSelected, dataDate?.[4]?.id];
  if (schedule?.isSaturday) listSelected = [...listSelected, dataDate?.[5]?.id];
  if (schedule?.isSunday) listSelected = [...listSelected, dataDate?.[6]?.id];
  return listSelected;
};

export const checkSchedule = (listSelected: number[], idSelected: number) => {
  if (listSelected?.includes(idSelected)) return true;
  else return false;
};

export const returnAspect = (width: number, height: number) => {
  if (
    width / height < 3 / 4 ||
    (3 / 4 <= width / height && width / height < 7 / 8)
  )
    return "3:4";
  else if (7 / 8 <= width / height && width / height < 7 / 6) return "1:1";
  else return "4:3";
};

export const returnTextStatusApplication = {
  applying: "status.applying",
  requestApply: "status.requestApply",
  rejected: "status.rejected",
  applying_cancelled: "status.applyingCancelled",
  hired: "status.hired",
  hire_cancelled: "status.hireCancelled",
  completed: "status.completed",
  store_cancelled: "status.shopCancelled",
  no_action: "status.noAction",
};

export const returnStatusApplication = (type: string) => {
  switch (type) {
    case applicationStatus.APPLYING:
      return "status.applying";
    case applicationStatus.REQUEST_APPLY:
      return "status.requestApply";
    case applicationStatus.REJECTED:
      return "status.rejected";
    case applicationStatus.APPLYING_CANCELLED:
      return "status.applyingCancelled";
    case applicationStatus.HIRED:
      return "status.hired";
    case applicationStatus.HIRED_CANCELLED:
      return "status.hireCancelled";
    case applicationStatus.COMPLETED:
      return "status.completed";
    case applicationStatus.SHOP_CANCELLED:
      return "status.shopCancelled";
    case applicationStatus.NO_ACTION:
      return "status.noAction";
    case applicationStatus.CLOSE:
      return "status.close";
  }
};

export const truncateText = ({
  value = "",
  length = 8,
}: {
  value: string;
  length?: number;
}) => {
  const truncatedValue =
    value.length > 8 ? `${value.slice(0, length)}...` : value;

  return truncatedValue;
};

export const getValidDayjsObj = ({
  field,
  value,
}: {
  field: string;
  value: any;
}) => {
  return dayjs(value).isValid()
    ? { [field]: dayjs(value) }
    : { [field]: undefined };
};

export const getTimeActive = (date: string) => {
  const minuteBefore = dayjs().diff(dayjs(date), "m");
  const hourBefore = dayjs().diff(dayjs(date), "h");
  const dateBefore = dayjs().diff(dayjs(date), "d");
  if (0 <= minuteBefore && minuteBefore < 60)
    return `${minuteBefore}分前にログイン`;
  else if (0 < hourBefore && hourBefore < 24)
    return `${hourBefore}時間前にログイン`;
  else if (dateBefore > 0 && dateBefore < 7)
    return `${dateBefore}日前にログイン`;
  else if (dateBefore >= 7) return "1週間以上";
  return "1週間以上";
  // return title;
};

export const renderIsActive = (data?: any) => {
  if (!data) return "";
  if (data?.isOnline) {
    return "オンライン中";
  }
  return getTimeActive(data?.lastTimeOnline);
};

export const renderTextAgencyLine = (
  operationLineAccounts: any,
  listLine: any[] | undefined
) => {
  if (!listLine) return "";
  if (operationLineAccounts?.length > 0)
    return operationLineAccounts
      ?.map((item: any) => item?.lineAccountName)
      ?.join(",");
  if (listLine?.length > 0 && operationLineAccounts?.length === 0)
    return "未設定";
  if (listLine?.length === 0) return "設定なし";
};

import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface IProps {
  pageIndex: number;
  pageSize?: number;
  totalItems: number;
  onPageChange: (page: number, pageSize: number) => void;
  className?: string;
}

export default function PaginationCustom({
  pageIndex,
  pageSize,
  totalItems,
  onPageChange,
  className,
}: IProps) {
  const [t] = useTranslation();

  const showTotal = (total: number, range: [number, number]) => {
    return t("table.showTotal", {
      rangeStart: range[0],
      rangeEnd: range[1],
      total: total,
    });
  };

  return (
    <div className={classNames(className)}>
      <Pagination
        current={Number(pageIndex)}
        total={totalItems}
        className="paginationCustom"
        showTotal={showTotal}
        showSizeChanger={false}
        onChange={onPageChange}
        pageSize={pageSize}
      />
    </div>
  );
}
